/* eslint-disable */
import React from 'react'
import Helmet from 'react-helmet'

import { graphql } from 'gatsby'
import { Link, Typography, VariableContent } from '@leshen/gatsby-theme-leshen'
import { Layout } from '@leshen/gatsby-theme-contentful'
import header from '../components/Header'
import footer from '../components/Footer'

const Home = ({ data }) => {
  const brandyList = ['disclaimer-hcp-indexlp']

  const layoutProps = {
    data: {
      contentfulPage: {
        header: header(),
        footer: footer(brandyList, data.allBrandyDisclaimer.edges),
        seo: {
          canonical: 'https://medicarehealthplans.com/404',
          robots: 'follow,index',
        },
        path: '/404',
        hideStickyCTA: false,
        spanish: false,
        sections: [],
      },
      site: {
        siteMetadata: {
          siteURL: 'https://medicarehealthplans.com',
          siteName: 'MedicareHealthPlans',
          alternateName: 'MedicareHealthPlans',
          removeTrailingSlashes: true,
        },
      },
    },
    titleTemplate: 'MedicareHealthPlans | 404',
    defaultRobots: 'follow,index',
    header: header(),
    footer: footer(brandyList, data.allBrandyDisclaimer.edges),
    main: (
      <>
        <Helmet>
          <script
            src="https://js.adsrvr.org/up_loader.1.1.0.js"
            type="text/javascript"
          ></script>
          <script type="text/javascript">
            {`
            if (typeof ttd_dom_ready === 'function' && typeof TTDUniversalPixelApi === 'function') {
          ttd_dom_ready( function() {
              var universalPixelApi = new TTDUniversalPixelApi();
              universalPixelApi.init("yzrdth4", ["rg74cyb"], "https://insight.adsrvr.org/track/up");
            });
          }
          `}
          </script>
        </Helmet>
        <VariableContent
          backgroundColor="white"
          mainContent={
            <>
              <Typography variant="h1">
                OOPS!
                <br /> SOMETHING WENT WRONG
              </Typography>
              <Typography style={{ marginBottom: '26px' }}>
                The page you're looking for can't be found. Please check your
                URL or return to the <Link to="/">home page.</Link>
              </Typography>
            </>
          }
          alignMainContent="center"
        ></VariableContent>
      </>
    ),
    disableBreadcrumbs: false,
  }

  return <Layout {...layoutProps} />
}
export default Home

export const query = graphql`
  query HomeQuery {
    allBrandyDisclaimer {
      edges {
        node {
          brandy_id
          text
          symbol
        }
      }
    }
  }
`
